import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { motion } from "framer-motion";
import { useState } from "react";
import styles from "./styles.module.scss";

export const AccordionSportItem = ({
	children,
	title,
	image,
	quantity,
	handler,
	id,
	sport,
}: any) => {
	const [toggle, setToggle] = useState(false);
	const { isDesktop } = useResponsive();

	const handleToggle = () => {
		if (handler) {
			if (!isDesktop) {
				if (toggle) {
					handler(id, sport);
				}

				setToggle(!toggle);
			} else {
				handler(id, sport);
				setToggle(!toggle);
			}
		} else {
			setToggle(!toggle);

			if (toggle) {
				handler(id, sport);
			}
		}
	};

	return (
		<motion.section initial={true} className={styles.accordionSportItem}>
			<motion.div
				className={styles.accordionTitleSportItem}
				onClick={() => handleToggle()}
				layout
			>
				<div className={styles.rigth}>
					{image}
					{title}
				</div>

				<p className={styles.number}>{quantity}</p>
			</motion.div>
			<motion.div className={styles.sportITemChildreen}>
				{toggle ? children : ""}
			</motion.div>
			<div className={styles.accordionLine} />
		</motion.section>
	);
};
