import Image from "next/image";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./styles.module.scss";

export const HeaderForMobileMenu = ({ closeFunction }: any) => {
	return (
		<div className={styles.navigationMobileHeading}>
			<div className={styles.logoMenu}>
				<Image src="/assets/img/logo.png" alt="logo" />
			</div>

			<div className={styles.iconClose}>
				<AiOutlineClose onClick={() => closeFunction(false)} />
			</div>
		</div>
	);
};
