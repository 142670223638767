import { HeaderForMobileMenu } from "@/bet-nextv1/HeaderForMobileMenu/index,";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { FirebaseContext } from "@/bet-nextv1/context/FirebaseContext";
import { getFlag } from "@/bet-nextv1/utils/getFlag";
import redirectTo from "@/bet-nextv1/utils/redirectTo";
import { useCore } from "@/core-nextv3/core/core";
import { useResponsive } from "@/core-nextv3/util/useResponsive";
import { useSubCore } from "@/hooks/useSubCore";
import { THEME_SETTING } from "@/setting/setting";
import { doc, onSnapshot } from "firebase/firestore";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { memo, useContext, useEffect, useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { BannersLinks } from "../BannersLinks";
import { AccordionSport } from "./AccordionSport";
import { AccordionSportItem } from "./AccordionSportItem";
import { AccordionSports } from "./AccordionSports";
import styles from "./styles.module.scss";

const NavigationSportsTwo = ({
	closePopularEvents,
	openModalPopularEvents,
	sizeBanners,
	account,
}: any) => {
	const [navigation, setNavigation] = useState<any>();

	const {
		games,
		locations,
		setSelectedLeague,
		setSelectedLocation,
		setSelectedSport,
		setReloadGames,
		setVirtual,
		setEndDate,
		setStartDate,
		setFilterPerLeague,
		setOpenLoginModal,
	} = useSubCore();
	const { isDesktop } = useResponsive();
	const router = useRouter();
	const { setAwaitLoadingHTTP, selectedLanguage } = useCore();
	const firebaseApp = useContext(FirebaseContext);
	const { t } = useTranslation();

	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user) {
			onSnapshot(
				doc(
					firebaseApp.firestore,
					"default/fixtureMenu/flatFixtureMenu/default",
				),
				async (querySnapshot) => {
					const data = querySnapshot.data()?.data;
					const desiredOrder = [6046, 48242, 154914, 154830, 35232];

					const sorted = data?.sort((a: any, b: any) => {
						const indexA = desiredOrder.indexOf(Number.parseInt(a.id));
						const indexB = desiredOrder.indexOf(Number.parseInt(b.id));

						if (indexA === -1 && indexB === -1) return 0;
						if (indexA === -1) return 1;
						if (indexB === -1) return -1;
						return indexA - indexB;
					});

					setNavigation(sorted);
				},
			);
		}
	}, [games, locations]);

	const handleSelectCountry = async (id: any, sport: any) => {
		if (router?.asPath !== "/sports/") {
			setAwaitLoadingHTTP(true);
		}

		setSelectedSport(sport.id ? sport.id : "esports");
		setVirtual(!sport.id);
		setSelectedLocation(id);
		// setSelectedLeague(null)
		setFilterPerLeague(true);
		setStartDate(null);

		if (router?.asPath !== "/sports/") {
			redirectTo("/sports/", user, router, setOpenLoginModal);
		} else {
			setReloadGames(true);
		}

		if (closePopularEvents) {
			closePopularEvents(false);
		}

		setAwaitLoadingHTTP(false);
	};

	const handleSelectLeague = async (league: any, sport: any) => {
		if (router?.asPath !== "/sports/") {
			setAwaitLoadingHTTP(true);
		}

		setSelectedSport(sport.id ? sport.id : "esports");
		setVirtual(!sport.id);

		setSelectedLeague(league);

		setEndDate(null);
		setSelectedLocation(null);

		setReloadGames(true);

		if (router?.asPath !== "/sports/") {
			redirectTo("/sports/", user, router, setOpenLoginModal);
		}

		if (closePopularEvents) {
			closePopularEvents(false);
		}

		setAwaitLoadingHTTP(false);
	};

	if (!isDesktop && openModalPopularEvents === true) {
		return (
			<div className={styles.navigationSportsMobile}>
				<div className={styles.container}>
					<HeaderForMobileMenu closeFunction={closePopularEvents} />

					<div className={styles.content}>
						<AccordionSports
							title={t("Esportes")}
							image={<img src="/assets/img/sportscash.png" alt="" />}
						>
							{navigation?.length > 0 &&
								navigation?.map((sport: any, key: any) => (
									<AccordionSport
										key={key}
										sport={sport}
										quantity={sport?.count}
										title={
											selectedLanguage === "pt"
												? sport?.name_br
												: sport?.name_ko
										}
										handler={closePopularEvents}
									>
										{sport?.children?.map((location: any, key2: any) => (
											<AccordionSportItem
												key={key2}
												title={
													selectedLanguage === "pt"
														? location?.name_br
														: location?.name_ko
												}
												image={<img src={getFlag(location)} alt="" />}
												quantity={location?.count}
												id={location.id}
												sport={sport}
												handler={handleSelectCountry}
											>
												{location?.children?.map((league: any, key3: any) => (
													<div
														key={key3}
														onClick={() => handleSelectLeague(league, sport)}
														className={styles.child}
													>
														<p className={styles.name}>
															{selectedLanguage === "pt"
																? league?.name_br
																: league?.name_ko}
														</p>
														<p className={styles.name}>{league?.count}</p>
														<BsArrowRightShort />
													</div>
												))}
											</AccordionSportItem>
										))}
									</AccordionSport>
								))}
						</AccordionSports>

						{/* <AccordionSports
              title={t('Liga Popular')}
              image={<BsFillTrophyFill />}
            ></AccordionSports> */}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className={`${styles.navigationSportsTwo} ${THEME_SETTING.headerInMiddle && styles.alternatePadding}`}
		>
			{THEME_SETTING.alternateLeftMenu ? (
				<THEME_SETTING.alternateLeftMenu account={account} />
			) : (
				<>
					<div className={styles.boxNavigation}>
						<p
							onClick={() =>
								redirectTo("/liveCasino", user, router, setOpenLoginModal)
							}
						>
							<img src="/assets/icon/Live-Casino.png" alt="" />{" "}
							{t("live casino")}
						</p>
						<p
							onClick={() =>
								redirectTo("/slotsCasino", user, router, setOpenLoginModal)
							}
						>
							<img src="/assets/icon/Slot.png" alt="" /> 슬롯게임
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=0",
									user,
									router,
									setOpenLoginModal,
								)
							}
							// onClick={() => router.push("/deposit/?tabIndex=0")}
						>
							<img src="/assets/icon/Deposit.png" alt="" /> {t("Depósito")}
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=1",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/Withdraw.png" alt="" /> {t("Saque")}
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=2",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/Money-Exchange.png" alt="" />{" "}
							{t("Transferencias")}
						</p>
						{/* <p 
                    onClick={() => redirectTo("/deposit/?tabIndex=5", user, router, setOpenLoginModal)}
                >
                    <img src="/assets/img/betting-history.png" alt=""/>  {t("Histórico de Apostas")}
                </p>
                <p 
                    onClick={() => redirectTo("/deposit/?tabIndex=6", user, router, setOpenLoginModal)}
                >
                    <img src="/assets/img/betting-history.png" alt=""/> {t("Histórico de Cash")}
                </p> */}
					</div>

					<div className={styles.boxNavigation}>
						{/* <p onClick={() => router.push("/deposit/?tabIndex=5")}><img src="/assets/icon/Betting-History.png" alt="" />{t("historico de bet")}</p> */}
						<p
							onClick={() =>
								redirectTo("/event", user, router, setOpenLoginModal)
							}
						>
							<img src="/assets/icon/Event.png" alt="" />
							{t("Evento")}
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=7",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/News.png" alt="" />
							{t("Rules")}
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=3",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/Message.png" alt="" />
							{t("Customer center")}
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=8",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/Loosing-Bonus.png" alt="" />
							루징신청
						</p>
						<p
							onClick={() =>
								redirectTo(
									"/deposit/?tabIndex=4",
									user,
									router,
									setOpenLoginModal,
								)
							}
						>
							<img src="/assets/icon/Chat.png" alt="" />
							쪽지함
						</p>
						{/* <p
                    onClick={() => redirectTo("/deposit/?tabIndex=7", user, router, setOpenLoginModal)}
                >
                    
                    <img src="/assets/icon/Indications.png" alt="" />지인추천
                </p> */}
						{/* <img src="/assets/icon/News.png" alt="" />{t("Aviso")} */}
						<p
							onClick={() =>
								redirectTo("/checking", user, router, setOpenLoginModal)
							}
						>
							<img src="/assets/icon/Calendar.png" alt="" />
							{t("Checkin")}
						</p>
						<p
							onClick={() =>
								redirectTo("/perfil", user, router, setOpenLoginModal)
							}
						>
							<img src="/assets/icon/My-Page.png" alt="" />
							{t("My page")}
						</p>
						{/* <p><img src="/assets/icon/Community.png" alt="" />{t("Detalhes da recomendação")}</p> */}
						<p onClick={() => window.location.reload(true)}>
							<img src="/assets/icon/4208523.png" alt="" />
							<span>쿠키삭제</span>
						</p>
					</div>
				</>
			)}

			{/* <div className={styles.navigationGrid}>
                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/deposito.png' alt='' />
                    <a
                        className={styles.title}
                    >
                        {t("Depósito")}
                    </a>
                </div>

                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/payout.png' alt='' />
                    <a
                        className={styles.title}

                    >
                        {t("Saque")}
                    </a>
                </div>

                <div
                    className={`${styles.subMenuGridItem} ${styles.firstRow}`}
                    onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/customer.png' alt='' />
                    <a
                        className={styles.title}

                    >
                        {t("Perguntas")}
                    </a>
                </div>

                <div
                    className={styles.subMenuGridItem}
                    onClick={() => 
                    {
                        setReloadGames(true);
                        setSelectedSport(null);
                        redirectTo("/sports", user, router, setOpenLoginModal)
                    }}
                >
                    <a
                        className={
                            router.asPath === "/sports/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Esporte")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/liveSports", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/liveSports/"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Live Sports")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=5"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Histórico de Apostas")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/mini/mgm/baccarat/1", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/mini/mgm/baccarat/1/"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Mini Game")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/casino", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/casino/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Cassino")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=2"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Movimentação")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/event/" ? styles.titleActive : styles.title
                        }

                    >
                        {t("Evento")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=7"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Avisos / Regras")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}>
                    <a
                        className={
                            router.asPath === "/deposit/?tabIndex=6"
                                ? styles.titleActive
                                : styles.title
                        }

                    >
                        {t("Histórico de Cash")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/boscore/ladder/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("boscore") ? styles.titleActive : styles.title
                        }

                    >
                        {t("Boscore")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/lotus/baccarat/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("lotus") ? styles.titleActive : styles.title
                        }

                    >
                        {t("Lotus")}
                    </a>
                </div>

                <div className={styles.subMenuGridItem} onClick={() => 
                {
                    redirectTo("/mini/mgm/baccarat/1/", user, router, setOpenLoginModal) 
                }}>
                    <a
                        className={
                            router.asPath.includes("mgm") ? styles.titleActive : styles.title
                        }

                    >
                        {t("MGM")}
                    </a>
                </div>
            </div> */}

			{/* <AccordionSports
                title={t("Esportes")}
                image={<img src='/assets/img/sportscash.png' alt='' />}
            >
                {navigation?.length > 0 &&
          navigation?.map((sport: any, key: any) => (
              <AccordionSport
                  key={key}
                  quantity={12}
                  title={
                      selectedLanguage === "pt" ? sport?.name_br : sport?.name_ko
                  }
                  sport={sport}
              >
                  {sport?.children?.map((location: any, key2: any) => (
                      <AccordionSportItem
                          key={key2}
                          title={
                              selectedLanguage === "pt"
                                  ? location?.name_br
                                  : location?.name_ko
                          }
                          image={<img src={getFlag(location)} alt='' />}
                          quantity={location?.count}
                          id={location.id}
                          sport={sport}
                          handler={handleSelectCountry}
                      >
                          {location?.children?.map((league: any, key3: any) => (
                              <div
                                  key={key3}
                                  onClick={() => handleSelectLeague(league, sport)}
                                  className={styles.child}
                              >
                                  <p className={styles.name}>
                                      {selectedLanguage === "pt"
                                          ? league?.name_br
                                          : league?.name_ko}
                                  </p>
                                  <div>
                                      <p>{league?.count}</p>
                                      <BsArrowRightShort />
                                  </div>
                              </div>
                          ))}
                      </AccordionSportItem>
                  ))}
              </AccordionSport>
          ))}
            </AccordionSports> */}

			{/* <AccordionSports
        title={t('Liga Popular')}
        image={<BsFillTrophyFill />}
      ></AccordionSports> */}

			{/* <HighlightsWidget type={"games"}></HighlightsWidget>
            <HighlightsWidget type={"leagues"}></HighlightsWidget> */}

			{THEME_SETTING.leftMenuBanners && (
				<BannersLinks sizeBanners={sizeBanners} />
			)}
		</div>
	);
};

export default memo(NavigationSportsTwo);
