export const getFlag = (location: any) => {
	// try {
	//     require(`public/assets/img/flags/${location?.id}.png`);
	//     return `/assets/img/flags/${location?.id}.png`;
	// }
	// catch (e) {
	//     return `/assets/img/flags/default.png`;
	// }
	if (location) {
		return `/assets/img/flags/${location?.id}.png`;
	}
	return "/assets/img/flags/default.png";
};
